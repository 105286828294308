<template>
  <select v-model="selected" :disabled="disabled" :required="mandatory">
    <option value="" :selected="selected === ''" :disabled="mandatory" :hidden="mandatory">
      Seleziona una provincia {{ mandatory ? " *" : '' }}
    </option>
    <option v-if="estero" :key="italia.length+1" class="provincia-estera" :selected="selected === 'ESTERO'" :value="'ESTERO'">
      ESTERO
    </option>
    <option v-for="(provincia, index) in italia" :key="index" :selected="selected === provincia.sigla" :value="provincia.sigla">
      {{ provincia.nome }}
    </option>
  </select>
</template>

<script type="ts">
export default {
  prop: ['value'],
  props: {
    mandatory: {
      type: Boolean,
      default: false
    },
    valore: {
      type: String,
      default: ''
    },
    estero: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      italia: [
        {
          nome: 'Agrigento',
          sigla: 'AG'
        },
        {
          nome: 'Alessandria',
          sigla: 'AL'
        },
        {
          nome: 'Ancona',
          sigla: 'AN'
        },
        {
          nome: 'Aosta',
          sigla: 'AO'
        },
        {
          nome: 'Arezzo',
          sigla: 'AR'
        },
        {
          nome: 'Ascoli Piceno',
          sigla: 'AP'
        },
        {
          nome: 'Asti',
          sigla: 'AT'
        },
        {
          nome: 'Avellino',
          sigla: 'AV'
        },
        {
          nome: 'Bari',
          sigla: 'BA'
        },
        {
          nome: 'Barletta-Andria-Trani',
          sigla: 'BT'
        },
        {
          nome: 'Belluno',
          sigla: 'BL'
        },
        {
          nome: 'Benevento',
          sigla: 'BN'
        },
        {
          nome: 'Bergamo',
          sigla: 'BG'
        },
        {
          nome: 'Biella',
          sigla: 'BI'
        },
        {
          nome: 'Bologna',
          sigla: 'BO'
        },
        {
          nome: 'Bolzano/Bozen',
          sigla: 'BZ'
        },
        {
          nome: 'Brescia',
          sigla: 'BS'
        },
        {
          nome: 'Brindisi',
          sigla: 'BR'
        },
        {
          nome: 'Cagliari',
          sigla: 'CA'
        },
        {
          nome: 'Caltanissetta',
          sigla: 'CL'
        },
        {
          nome: 'Campobasso',
          sigla: 'CB'
        },
        {
          nome: 'Carbonia-Iglesias',
          sigla: 'CI'
        },
        {
          nome: 'Caserta',
          sigla: 'CE'
        },
        {
          nome: 'Catania',
          sigla: 'CT'
        },
        {
          nome: 'Catanzaro',
          sigla: 'CZ'
        },
        {
          nome: 'Chieti',
          sigla: 'CH'
        },
        {
          nome: 'Como',
          sigla: 'CO'
        },
        {
          nome: 'Cosenza',
          sigla: 'CS'
        },
        {
          nome: 'Cremona',
          sigla: 'CR'
        },
        {
          nome: 'Crotone',
          sigla: 'KR'
        },
        {
          nome: 'Cuneo',
          sigla: 'CN'
        },
        {
          nome: 'Enna',
          sigla: 'EN'
        },
        {
          nome: 'Fermo',
          sigla: 'FM'
        },
        {
          nome: 'Ferrara',
          sigla: 'FE'
        },
        {
          nome: 'Firenze',
          sigla: 'FI'
        },
        {
          nome: 'Foggia',
          sigla: 'FG'
        },
        {
          nome: 'Forlì-Cesena',
          sigla: 'FC'
        },
        {
          nome: 'Frosinone',
          sigla: 'FR'
        },
        {
          nome: 'Genova',
          sigla: 'GE'
        },
        {
          nome: 'Gorizia',
          sigla: 'GO'
        },
        {
          nome: 'Grosseto',
          sigla: 'GR'
        },
        {
          nome: 'Imperia',
          sigla: 'IM'
        },
        {
          nome: 'Isernia',
          sigla: 'IS'
        },
        {
          nome: 'L\'Aquila',
          sigla: 'AQ'
        },
        {
          nome: 'La Spezia',
          sigla: 'SP'
        },
        {
          nome: 'Latina',
          sigla: 'LT'
        },
        {
          nome: 'Lecce',
          sigla: 'LE'
        },
        {
          nome: 'Lecco',
          sigla: 'LC'
        },
        {
          nome: 'Livorno',
          sigla: 'LI'
        },
        {
          nome: 'Lodi',
          sigla: 'LO'
        },
        {
          nome: 'Lucca',
          sigla: 'LU'
        },
        {
          nome: 'Macerata',
          sigla: 'MC'
        },
        {
          nome: 'Mantova',
          sigla: 'MN'
        },
        {
          nome: 'Massa-Carrara',
          sigla: 'MS'
        },
        {
          nome: 'Matera',
          sigla: 'MT'
        },
        {
          nome: 'Medio Campidano',
          sigla: 'VS'
        },
        {
          nome: 'Messina',
          sigla: 'ME'
        },
        {
          nome: 'Milano',
          sigla: 'MI'
        },
        {
          nome: 'Modena',
          sigla: 'MO'
        },
        {
          nome: 'Monza e della Brianza',
          sigla: 'MB'
        },
        {
          nome: 'Napoli',
          sigla: 'NA'
        },
        {
          nome: 'Novara',
          sigla: 'NO'
        },
        {
          nome: 'Nuoro',
          sigla: 'NU'
        },
        {
          nome: 'Ogliastra',
          sigla: 'OG'
        },
        {
          nome: 'Olbia-Tempio',
          sigla: 'OT'
        },
        {
          nome: 'Oristano',
          sigla: 'OR'
        },
        {
          nome: 'Padova',
          sigla: 'PD'
        },
        {
          nome: 'Palermo',
          sigla: 'PA'
        },
        {
          nome: 'Parma',
          sigla: 'PR'
        },
        {
          nome: 'Pavia',
          sigla: 'PV'
        },
        {
          nome: 'Perugia',
          sigla: 'PG'
        },
        {
          nome: 'Pesaro e Urbino',
          sigla: 'PU'
        },
        {
          nome: 'Pescara',
          sigla: 'PE'
        },
        {
          nome: 'Piacenza',
          sigla: 'PC'
        },
        {
          nome: 'Pisa',
          sigla: 'PI'
        },
        {
          nome: 'Pistoia',
          sigla: 'PT'
        },
        {
          nome: 'Pordenone',
          sigla: 'PN'
        },
        {
          nome: 'Potenza',
          sigla: 'PZ'
        },
        {
          nome: 'Prato',
          sigla: 'PO'
        },
        {
          nome: 'Ragusa',
          sigla: 'RG'
        },
        {
          nome: 'Ravenna',
          sigla: 'RA'
        },
        {
          nome: 'Reggio di Calabria',
          sigla: 'RC'
        },
        {
          nome: 'Reggio nell\'Emilia',
          sigla: 'RE'
        },
        {
          nome: 'Rieti',
          sigla: 'RI'
        },
        {
          nome: 'Rimini',
          sigla: 'RN'
        },
        {
          nome: 'Roma',
          sigla: 'RM'
        },
        {
          nome: 'Rovigo',
          sigla: 'RO'
        },
        {
          nome: 'Salerno',
          sigla: 'SA'
        },
        {
          nome: 'Sassari',
          sigla: 'SS'
        },
        {
          nome: 'Savona',
          sigla: 'SV'
        },
        {
          nome: 'Siena',
          sigla: 'SI'
        },
        {
          nome: 'Siracusa',
          sigla: 'SR'
        },
        {
          nome: 'Sondrio',
          sigla: 'SO'
        },
        {
          nome: 'Taranto',
          sigla: 'TA'
        },
        {
          nome: 'Teramo',
          sigla: 'TE'
        },
        {
          nome: 'Terni',
          sigla: 'TR'
        },
        {
          nome: 'Torino',
          sigla: 'TO'
        },
        {
          nome: 'Trapani',
          sigla: 'TP'
        },
        {
          nome: 'Trento',
          sigla: 'TN'
        },
        {
          nome: 'Treviso',
          sigla: 'TV'
        },
        {
          nome: 'Trieste',
          sigla: 'TS'
        },
        {
          nome: 'Udine',
          sigla: 'UD'
        },
        {
          nome: 'Varese',
          sigla: 'VA'
        },
        {
          nome: 'Venezia',
          sigla: 'VE'
        },
        {
          nome: 'Verbano-Cusio-Ossola',
          sigla: 'VB'
        },
        {
          nome: 'Vercelli',
          sigla: 'VC'
        },
        {
          nome: 'Verona',
          sigla: 'VR'
        },
        {
          nome: 'Vibo Valentia',
          sigla: 'VV'
        },
        {
          nome: 'Vicenza',
          sigla: 'VI'
        },
        {
          nome: 'Viterbo',
          sigla: 'VT'
        }
      ]
    }
  },
  computed: {
    selected: {
      get () { return this.valore },
      set (v) { this.$emit('input', v) }
    }
  }
}

</script>
<style>
.provincia-estera{
  margin-top: 10px;
  border-top: 1px solid black;
}
</style>
